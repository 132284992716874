import React, { Dispatch, SetStateAction } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    useMediaQuery,
    useTheme,
} from 'shared-components/material/core'
import { RadioInput, TextInput } from 'shared-components/inputs'
import ErrorModal from 'shared-components/modals/ErrorModal'
import { CenteredContent, CenteredForm } from 'shared-components/layout';

export const ADD_EX_SET = gql`
  mutation AddExSet($disputeId: Int!, $exSetPrefix: String!, $exSetName: String!, $exSetSequencingType: Int, $exSetSequencingStartNum: Int) {
  addExSet(disputeId: $disputeId, exSetPrefix: $exSetPrefix, exSetName: $exSetName, exSetSequencingType: $exSetSequencingType, exSetSequencingStartNum: $exSetSequencingStartNum) {
    exSetId
  }
}
`

const schema = yup.object({
    exSetName: yup.string().required('Exhibit name is required'),
    exSetPrefix: yup.string().required('Exhibit prefix is required'),
    exSetSequencingType: yup.number().required('Exhibit sequencing Type is required'),
    exSetSequencingStartNum: yup
        .number()
        .when('exSetSequencingType', {
            is: 1,
            then: yup.number().required("Sequencing start number is required")
        }),
}).required()

type FormData = {
    exSetName: string,
    exSetPrefix: string,
    exSetSequencingType: number,
    exSetSequencingStartNum: number,
}

const exSetSequencingTypeOptions = [
    { label: 'Numeric', value: 1 },
    { label: 'Alphabetic', value: 2 },
]

type AddExhibitSetModalProps = {
    openAddExhibitSet: boolean,
    setOpenAddExhibitSet: Dispatch<SetStateAction<boolean>>,
    refetchExhibitSets: () => void,
    disputeId: number
}

export default function AddExhibitSetModal({ openAddExhibitSet, setOpenAddExhibitSet, refetchExhibitSets, disputeId }: AddExhibitSetModalProps) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [addExSet, { loading: addExSetLoading, error: addExSetError }] = useMutation(ADD_EX_SET)
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            exSetName: '',
            exSetPrefix: '',
            exSetSequencingType: 1,
            exSetSequencingStartNum: 1,
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, reset, watch, formState: { errors } } = methods

    const exSetSequencingType = watch('exSetSequencingType')

    const onSubmit = async (data: FormData) => {
        const variables = {...data, disputeId}
        await addExSet({variables})
        refetchExhibitSets()
        reset()
        setOpenAddExhibitSet(false)
    }

    if (addExSetLoading) {
        <CenteredContent>
            <CircularProgress />
        </CenteredContent>
    }

    return (
        <Dialog open={openAddExhibitSet} onClose={() => setOpenAddExhibitSet(false)} maxWidth={'sm'} fullScreen={fullScreen}>
            <FormProvider {...methods}>
                <DialogTitle>Create Exhibit Set</DialogTitle>
                <DialogContent>
                    <CenteredForm>
                        <TextInput
                            name="exSetName"
                            label="Exhibit Set Name"
                            required
                            error={errors.exSetName !== undefined ? true : false }
                            errorMessage={errors.exSetName ? errors.exSetName.message : undefined}
                        />
                        <TextInput
                            name="exSetPrefix"
                            label="Exhibit Set Prefix"
                            required
                            error={errors.exSetPrefix !== undefined ? true : false }
                            errorMessage={errors.exSetPrefix ? errors.exSetPrefix.message : undefined}
                        />
                        <RadioInput 
                            label="Exhibit Set Sequencing Type"
                            name="exSetSequencingType"
                            options={exSetSequencingTypeOptions}
                            defaultValue={1}
                            required
                        />
                        {exSetSequencingType == 1 ? (
                            <TextInput
                                name="exSetSequencingStartNum"
                                label="Sequence Start Number"
                                type="number"
                                required
                                error={errors.exSetSequencingStartNum !== undefined ? true : false }
                                errorMessage={errors.exSetSequencingStartNum ? errors.exSetSequencingStartNum.message : undefined}
                            /> 
                        ) : null}
                    </CenteredForm>
                </DialogContent>
                <DialogActions>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                        <Button 
                            onClick={() => setOpenAddExhibitSet(false)}
                            variant="outlined" color="primary"
                        >
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleSubmit(onSubmit)} 
                            variant="contained" color="primary">
                            Add Exhibit Set
                        </Button>
                    </div>
                </DialogActions>
            </FormProvider>
            <ErrorModal error={addExSetError && addExSetError.message || ''}  />
        </Dialog>
    )
}
