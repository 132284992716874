import React, { useEffect } from 'react'
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { gql, useMutation } from '@apollo/client'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    LoadingButton,
} from 'shared-components/material/core'
import { RadioInput, TextInput } from 'shared-components/inputs'
import ErrorModal from '../ErrorModal'
import { ExSet } from 'generated/graphql'
import { CenteredForm } from 'shared-components/layout'

export const UPDATE_EX_SET = gql`
   mutation UpdateExSet($exSetId: Int!, $exSetPrefix: String, $exSetSequencingType: Int, $exSetSequencingStartNum: Int) {
  updateExSet(exSetId: $exSetId, exSetPrefix: $exSetPrefix, exSetSequencingType: $exSetSequencingType, exSetSequencingStartNum: $exSetSequencingStartNum) {
    exSetId
  }
}
`

const schema = yup.object({
    exSetPrefix: yup.string(),
    exSetSequencingType: yup.number(),
    exSetSequencingStartNum: yup
        .number()
        .when('exSetSequencingType', {
            is: 1,
            then: yup.number().required("Sequencing start number is required")
        }),
}).required()

type FormData = {
    exSetSequencingType: number,
    exSetPrefix: string,
    exSetSequencingStartNum: number,
}

type OptionsProps = { 
    exSet: ExSet,
    togglePayModal: () => void,
    handleNext: () => void,
    getExSetCost: () => void,
    refetchData: () => void,
}

const exSetSequencingTypeOptions = [
    { label: 'Numeric', value: 1 },
    { label: 'Alphabetic', value: 2 },
]

export default function Options({ 
    exSet,
    togglePayModal,
    handleNext,
    getExSetCost,
    refetchData,
} : OptionsProps) {
    const [updateExSet, { loading: updateExSetLoading, error: updateExSetError }] = useMutation(UPDATE_EX_SET)
    const methods = useForm({
        defaultValues: { //create empty init values to avoid uncontrolled to controlled warning
            exSetSequencingType: 1,
            exSetPrefix: '',
            exSetSequencingStartNum: 1,
        },
        resolver: yupResolver(schema),
    })

    const { handleSubmit, setValue, watch, formState: { errors } } = methods

    const exSetSequencingType = watch('exSetSequencingType')

    useEffect(() => {
        setValue('exSetPrefix', exSet.exSetPrefix || '')
        setValue('exSetSequencingType', exSet.exSetSequencingType || 1)
        setValue('exSetSequencingStartNum', exSet.exSetSequencingStartNum || 1)
    }, [])

    const onSubmit = async (formData: FormData) => {
        const variables = {...formData, exSetId: exSet.exSetId}
        await updateExSet({ variables })
        await getExSetCost()
        await refetchData()
        await handleNext()
    }

    return (
        <>
            <DialogContent 
                sx={{ display: 'flex', height: "536px", justifyContent: 'center', alignItems: 'center' }}
            >
                <CenteredForm>
                    <FormProvider {...methods} >
                        <TextInput
                            name="exSetPrefix"
                            label="Exhibit Set Prefix"
                            required
                            error={errors.exSetPrefix !== undefined ? true : false }
                            errorMessage={errors.exSetPrefix ? errors.exSetPrefix.message : undefined}
                        />
                        <RadioInput
                            label="Exhibit Set Sequencing Type"
                            name="exSetSequencingType"
                            options={exSetSequencingTypeOptions}
                            defaultValue={exSet.exSetSequencingType || 1}
                            required
                        />
                        {exSetSequencingType == 1 ? (
                            <TextInput
                                name="exSetSequencingStartNum"
                                label="Sequence Start Number"
                                type="number"
                                required
                                error={errors.exSetSequencingStartNum !== undefined ? true : false }
                                errorMessage={errors.exSetSequencingStartNum ? errors.exSetSequencingStartNum.message : undefined}
                            /> 
                        ) : null}
                    </FormProvider>
                </CenteredForm>
            </DialogContent>
            <DialogActions>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pt: 2,
                    width: '100%'
                }}>
                    <Button 
                        onClick={togglePayModal} 
                        color="inherit"
                    >
                        Cancel
                    </Button>
                    {updateExSetLoading ? (
                        <LoadingButton loading variant="outlined">
                            Next
                        </LoadingButton>
                    ) : (
                        <Button 
                            onClick={handleSubmit(onSubmit)} 
                            variant="contained"
                        >
                            Submit Options
                        </Button>
                    )}

                </Box>
                <ErrorModal error={updateExSetError?.message || ''} />
            </DialogActions>
        </>
  )
}
